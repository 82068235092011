import React from "react"
import { useEffect, useState } from "react"
import styles from "./submit.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FaqItem from "../components/faqItem"
import { Send } from "react-feather"
import simpleScroll from "simple-scroll"

import siebe from "../images/siebe.jpeg"
import scrollImage from "../images/scroll-pub-crop.jpeg"
import paintmark from "../images/paint.png"
import Modal from "../components/modal"

const faqs = [
  {
    question: "Do I need already need to have a Shopify store?",
    answer: "No",
  },
  {
    question: "Do you posses the knowledge to help me with my project?",
    answer: "Yes",
  },
  {
    question: "How long will it take?",
    answer: "Depends",
  },
  {
    question: "Do I need to be technically Savvy?",
    answer: "No",
  },
]

const SubmitPage = () => {
  const [offset, setOffset] = useState(0)
  const [showPricingModal, setShowPricingModal] = useState(false)
  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const scrollDown = ev => {
    ev.preventDefault()
    simpleScroll.element("enquiryForm", 50, 300)
  }

  const scrollDownForm = ev => {
    ev.preventDefault()
    simpleScroll.element("enquiryForm", 0, 300)
  }

  return (
    <Layout>
      <SEO title="Submit a pub" />
      <div className={styles.header}>
        <div className={styles.content}>
          <h3>#SUBMIT</h3>
          <h1>Know a pub that should be featured on Outside Drinks?</h1>
          <p>Complete the form below to get them added to the list.</p>
          <button onClick={scrollDown}>
            <Send />
            Submit a pub
          </button>
          <div className={styles.scrollImage}>
            <div className={styles.scrollImageContainer}>
              <img
                src={scrollImage}
                style={{
                  transform: `translateY(${offset * -0.3}px)`,
                }}
              />
            </div>
          </div>

          <img className={styles.paintmark} src={paintmark} />
        </div>
      </div>

      <div className={`${styles.section} ${styles.testimonial}`}>
        <p className={styles.testimonialText}>
          "As a drinker of beer, searching for a great beer garden feels like a
          massive undertaking, you want to make sure you find the perfect spot -
          but where to start? Outside Drinks is my go-to site in a pinch when
          that thirst hits"
        </p>
        <div className={styles.testimonialDetails}>
          <img src={siebe} />
          <div className={styles.testimonialDetailsWrapper}>
            <span>Siebe</span>
          </div>
        </div>
      </div>

      {/* <div className={styles.section} id="shopifySetup">
        <h2>Shopify Setup</h2>
        <p>
          Not sure where to get started? We can <em>help setup</em> your site
          from scratch & <em>show you the ropes</em>
        </p>
        <div className={styles.setupButtons}>
          <button onClick={scrollDownForm}>
            <span>Basic Setup & Intro</span> $400
          </button>

          <button onClick={scrollDownForm}>
            <span>Full Setup & Intro</span> From $800
          </button>
        </div>
        <div className={styles.question}>What’s included: Basic vs Full</div>
      </div>

      <div className={styles.section}>
        <h2>Theme customisation</h2>
        <p>
          Go wild. super-charge your current theme &. make it feel more like you
          with custom looks & features.
        </p>
        <button onClick={scrollDownForm}>
          <span>Get your theme customized</span> From $100
        </button>
      </div> */}

      <div className={styles.section} id="enquiryForm">
        <h2>Suggest a pub</h2>
        {/* <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdTd4SzYg8SW_vOhXiJ1qgR5wOhHOQHtyZSZgWJA1WKAiadJQ/viewform?embedded=true"
          width="640"
          height="922"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="enquiryForm"
        >
          Loading…
        </iframe> */}
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdAP44JrkbXqvwqNcCDYAhE-C5tjrSqPO4sE-Qk4WzOGywL1w/viewform?embedded=true"
          width="640"
          height="685"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading…
        </iframe>
      </div>

      {/* <div className={styles.faqSection}>
        <h2>Questions? Look here.</h2>
        <p>Turn your brand into the total package</p>
        <div className={styles.questions}>
          {faqs.map(item => (
            <FaqItem item={item} key={item.question} />
          ))}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://calendly.com/undefined-siebe/discovery-call"
            className={styles.calendly}
          >
            <div className={styles.linkText}>
              <img src={siebe} />
              Not sure yet? — Book a free 15 minute consultation
            </div>

            <ArrowRight />
          </a>
        </div>
        <img className={styles.paintmark} src={paintmark} />
      </div> */}

      {/* {showPricingModal && (
        <Modal onClose={() => setShowPricingModal(false)}>
          <p>COMING SOON</p>
        </Modal>
      )} */}
    </Layout>
  )
}

export default SubmitPage
